:root{
    --surface: #f0f2f4;
    --palette: #ffffff;
    --navbar: #ffffff;
    --text: #505050;
    --background: #1b191f;
    --logo-dark-visibility: hidden;
}

:root[data-theme="dark"] {
    --surface: #101010;
    --palette: #000000;
    --navbar: #1a1a1a;
    --text: #e0e0e0;
    --background: #000000;
    --logo-dark-visibility: visible;
}

$surface-color: var(--surface);
$palette-color: var(--palette);
$navbar-color: var(--navbar);
$text-color: var(--text);
$background-color: var(--background);

$primary-color: #4ba395;

body {
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: $background-color;
    font-size: 16px;
}

a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
        color: darken($primary-color, 10%);
    }
}

.inner {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 800px) {
        max-width: 600px;
    }
}

.logo-dark {
    visibility: var(--logo-dark-visibility);
}

.brand {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
}

.reset-button {
    display: none;
    transition: all 0.2s;
}

.dark-mode-toggle-button {
    position: absolute;
    right: 6px;
    top: 8px;
    z-index: 10;
    cursor: pointer;
    fill: $text-color;
}

.content {
    background-color: $surface-color;
    padding: 90px 0 50px 0;
}

.container {
    width: 100%;
}

.hue, .chroma, .brightness, .value {
    display: none;
}

.rgb {
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 15px;
    top: 15px;
}

.box {
    background-color: #123456;
    float: left;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
}

.highlighted {
    box-shadow: 0 0 10px rgba(0,0,0,0.2) inset;
}

.footer {
    background-color: $background-color;
    color: #ffffff;

    .inner {
        padding: 40px 15px;
    }
}

.fb_iframe_widget {
    top: -6px;
}

.license {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;

    a {
        color: #ffffff;
    }
}

.palette {
    background-color: $palette-color;
    padding: 8px;
    border-radius: 0px 0px 4px 4px;
}

.palette-inner {
    width: 100%;
    position:relative;
}

.code-container{
    text-align: center;
    margin: 4px;
    padding: 40px 0;
    font-size: 18px;
    background-color: #cc285f;
    border-radius: 3px;
    color: #ffffff;
}

.code-input{
    text-align: center;
    width: 30%;
    color: darken($primary-color, 10%);
    border: none;
    border-radius: 3px;
}

input:focus, select:focus, textarea:focus, button:focus {
    outline: none;
}

/*bootstrap*/

.navbar {
    box-shadow: 0 0 2px rgba(0,0,0,.2);
    background-color: $navbar-color;
    height: 52px;
    user-select: none;
}

.btn{
    border: 0px;
}

.nav-tabs {
    border-bottom-style: none;
}

.nav-tabs > li {
    width: 50%;
    text-align:center;
}

.tab {
    padding: 0;
}

.nav-tabs .nav-link {
    cursor: pointer;
    border: none;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: $palette-color;
    color: $text-color;
}